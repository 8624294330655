var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"pa-0":"","fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs-group',{scopedSlots:_vm._u([{key:"tabs",fn:function(){return [_c('v-tab',{key:"api"},[_vm._v("API")]),_c('v-tab',{key:"location"},[_vm._v("Location")])]},proxy:true},{key:"items",fn:function(){return [_c('v-tab-item',{key:"api"},[_c('v-editable-pad',{attrs:{"title":"API"},on:{"save":_vm.saveApi},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var rules = ref.rules;
var disabled = ref.disabled;
return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"API Base URL","readonly":disabled},model:{value:(data.default.url),callback:function ($$v) {_vm.$set(data.default, "url", $$v)},expression:"data.default.url"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Local device API URL","readonly":disabled},model:{value:(data.localDevice.url),callback:function ($$v) {_vm.$set(data.localDevice, "url", $$v)},expression:"data.localDevice.url"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"OAuth2 API URL","readonly":disabled},model:{value:(data.authentication.url),callback:function ($$v) {_vm.$set(data.authentication, "url", $$v)},expression:"data.authentication.url"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"OAuth2 Client ID","readonly":disabled},model:{value:(data.authentication.clientId),callback:function ($$v) {_vm.$set(data.authentication, "clientId", $$v)},expression:"data.authentication.clientId"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"OAuth2 Client Secret","readonly":disabled},model:{value:(data.authentication.clientSecret),callback:function ($$v) {_vm.$set(data.authentication, "clientSecret", $$v)},expression:"data.authentication.clientSecret"}})],1)],1)],1)]}}]),model:{value:(_vm.api),callback:function ($$v) {_vm.api=$$v},expression:"api"}})],1),_c('v-tab-item',{key:"location"},[_c('v-editable-pad',{attrs:{"title":"Location"},on:{"save":_vm.saveLocation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var rules = ref.rules;
var disabled = ref.disabled;
return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-master-field',{attrs:{"custom-api":['models','Master','Locations','query'],"custom-api-text":"locationName","custom-api-value":"locationCode","customApiEagerLoad":"","value-object":_vm.currentLocation,"label":"สถานที่","show-code":"","readonly":disabled,"clearable":!disabled},on:{"update:valueObject":function($event){_vm.currentLocation=$event},"update:value-object":function($event){_vm.currentLocation=$event}},model:{value:(data.locationCode),callback:function ($$v) {_vm.$set(data, "locationCode", $$v)},expression:"data.locationCode"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"label":"สถานที่ย่อย","items":(_vm.currentLocation) ? _vm.currentLocation.subunits : undefined,"item-text":"subunitName","item-value":"subunitCode","readonly":disabled,"clearable":!disabled},model:{value:(data.locationSubunitCode),callback:function ($$v) {_vm.$set(data, "locationSubunitCode", $$v)},expression:"data.locationSubunitCode"}})],1)],1)],1)]}}]),model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }